<template>
  <div>
    <el-tabs
      type="border-card"
      class="group-tab"
      v-model="activeName"
      @tab-click="fetchDashboard"
    >
      <el-tab-pane name="all">
        <span slot="label">
          <p class="name-title">ทุกภาคส่วน</p>
          <p class="count-title">
            <span>{{ countTabAll | commaNoDot }}</span
            >หน่วย
          </p>
        </span>
        <el-row :gutter="30" class="custom-row">
          <el-col :span="24" :md="12" class="group-home-left">
            <img src="../assets/image/home-smid.png" class="w-100" alt="SMID" />
          </el-col>
          <el-col :span="24" :md="12" class="group-donut">
            <p class="home-title">งานนวัตกรรม</p>
            <p class="sub-tab-title">ประเภทของนวัตกรรมจากย่านการแพทย์สวนดอก</p>
            <div v-loading="loading" class="chart-height">
              <div v-if="tabDataRespon.series.reduce((a,b)=>a+b) > 0">
                <apexchart
                  ref="chartall"
                  type="donut"
                  width="100%"
                  height="auto"
                  :options="tabDataRespon.options"
                  :series="tabDataRespon.series"
                ></apexchart>
              </div>
              <p v-else-if="loading">กำลังโหลด...</p>
              <p v-else>ไม่มีข้อมูล</p>
            </div>
            <div class="show-total">
              <p class="name-this">
                ทรัพย์สินทางปัญญา
                <span>(ย้อนหลังล่าสุด 3 ปี) </span>
              </p>
              <p class="count-this">
                <span v-if="checkData == true">{{
                  intellectualList | commaNoDot
                }}</span>
                รายการ
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="group-fotter">
          <el-col :span="24">
            <p class="text-center home-title-detail">
              ข้อมูลบุคลากร และ อุปกรณ์ทางการแพทย์
            </p>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/doctor.png" alt="SMID DOCTOR" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count" v-if="checkData == true">
                    {{ countSpecialized | commaNoDot }}
                  </p>
                  <p class="name-group">บุคลากรเฉพาะทาง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/nurse.png" alt="SMID NURSE" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count" v-if="checkData == true">
                    {{ countSupoort | commaNoDot }}
                  </p>
                  <p class="name-group">บุคลากรสนับสนุน</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/student.png" alt="SMID STUDENT" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count" v-if="checkData == true">
                    {{ countStudent | commaNoDot }}
                  </p>
                  <p class="name-group">นักศึกษา</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/bed.png" alt="SMID BED" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count" v-if="checkData == true">
                    {{ countPatienBed | commaNoDot }}
                  </p>
                  <p class="name-group">เตียง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" class="see-all">
            <button
              type="button"
              @click="downLoadDocument()"
              class="btn-color-dark download"
            >
              <span>ดาวน์โหลด เอกสารสรุปนวัตกรรม</span>
            </button>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="1">
        <span slot="label">
          <p class="name-title">ภาครัฐ การศึกษา</p>
          <p class="count-title">
            <span>{{ countTab1 }}</span
            >หน่วย
          </p>
        </span>
        <el-row :gutter="30" class="custom-row">
          <el-col :span="24" :md="12" class="group-home-left">
            <img src="../assets/image/home-smid.png" class="w-100" alt="SMID HOME" />
          </el-col>
          <el-col :span="24" :md="12" class="group-donut">
            <p class="home-title">งานนวัตกรรม</p>
            <p class="sub-tab-title">ประเภทของนวัตกรรมจากย่านการแพทย์สวนดอก</p>
            <div v-loading="loading" class="chart-height">
              <div v-if="tabDataRespon.series.reduce((a,b)=>a+b) > 0">
                <apexchart
                  ref="chart1"
                  type="donut"
                  width="100%"
                  height="auto"
                  :options="tabDataRespon.options"
                  :series="tabDataRespon.series"
                ></apexchart>
              </div>
              <p v-else-if="loading">กำลังโหลด...</p>
              <p v-else>ไม่มีข้อมูล</p>
            </div>
            <div class="show-total">
              <p class="name-this">
                ทรัพย์สินทางปัญญา
                <span>(ย้อนหลังล่าสุด 3 ปี) </span>
              </p>
              <p class="count-this">
                <span v-if="checkData == true">{{
                  intellectualList | commaNoDot
                }}</span>
                รายการ
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="group-fotter">
          <el-col :span="24">
            <p class="text-center home-title-detail">
              ข้อมูลบุคลากร และ อุปกรณ์ทางการแพทย์
            </p>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/doctor.png" alt="SMID DOCTOR" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSpecialized | commaNoDot }}</p>
                  <p class="name-group">บุคลากรเฉพาะทาง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/nurse.png" alt="SMID NURSE" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSupoort | commaNoDot }}</p>
                  <p class="name-group">บุคลากรสนับสนุน</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/student.png" alt="SMID STUDENT" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countStudent | commaNoDot }}</p>
                  <p class="name-group">นักศึกษา</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/bed.png" alt="SMID BED" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count" v-if="checkData == true">
                    {{ countPatienBed | commaNoDot }}
                  </p>
                  <p class="name-group">เตียง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" class="see-all">
            <button
              type="button"
              @click="downLoadDocument()"
              class="btn-color-dark download"
            >
              <span>ดาวน์โหลด เอกสารสรุปนวัตกรรม</span>
            </button>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="2">
        <span slot="label">
          <p class="name-title">ภาครัฐ อื่นๆ</p>
          <p class="count-title">
            <span>{{ countTab2 }}</span
            >หน่วย
          </p>
        </span>
        <el-row :gutter="30" class="custom-row">
          <el-col :span="24" :md="12" class="group-home-left">
            <img src="../assets/image/home-smid.png" class="w-100" alt="SMID HOME" />
          </el-col>
          <el-col :span="24" :md="12" class="group-donut">
            <p class="home-title">งานนวัตกรรม</p>
            <p class="sub-tab-title">ประเภทของนวัตกรรมจากย่านการแพทย์สวนดอก</p>
            <div v-loading="loading" class="chart-height">
              <div v-if="tabDataRespon.series.reduce((a,b)=>a+b) > 0">
                <apexchart
                  ref="chart2"
                  type="donut"
                  width="100%"
                  height="auto"
                  :options="tabDataRespon.options"
                  :series="tabDataRespon.series"
                ></apexchart>
              </div>
              <p v-else-if="loading">กำลังโหลด...</p>
              <p v-else>ไม่มีข้อมูล</p>
            </div>
            <div class="show-total">
              <p class="name-this">
                ทรัพย์สินทางปัญญา
                <span>(ย้อนหลังล่าสุด 3 ปี) </span>
              </p>
              <p class="count-this">
                <span v-if="checkData == true">{{
                  intellectualList | commaNoDot
                }}</span>
                รายการ
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="group-fotter">
          <el-col :span="24">
            <p class="text-center home-title-detail">
              ข้อมูลบุคลากร และ อุปกรณ์ทางการแพทย์
            </p>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/doctor.png" alt="SMID DOCTOR" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSpecialized | commaNoDot }}</p>
                  <p class="name-group">บุคลากรเฉพาะทาง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/nurse.png" alt="SMID NURSE" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSupoort | commaNoDot }}</p>
                  <p class="name-group">บุคลากรสนับสนุน</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/student.png" alt="SMID STUDENT" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countStudent | commaNoDot }}</p>
                  <p class="name-group">นักศึกษา</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/bed.png" alt="SMID BED" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count" v-if="checkData == true">
                    {{ countPatienBed | commaNoDot }}
                  </p>
                  <p class="name-group">เตียง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" class="see-all">
            <button
              type="button"
              @click="downLoadDocument()"
              class="btn-color-dark download"
            >
              <span>ดาวน์โหลด เอกสารสรุปนวัตกรรม</span>
            </button>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="3">
        <span slot="label">
          <p class="name-title">ภาคเอกชน รพ.</p>
          <p class="count-title">
            <span>{{ countTab3 }}</span
            >หน่วย
          </p>
        </span>
        <el-row :gutter="30" class="custom-row">
          <el-col :span="24" :md="12" class="group-home-left">
            <img src="../assets/image/home-smid.png" class="w-100" alt="SMID HOME" />
          </el-col>
          <el-col :span="24" :md="12" class="group-donut">
            <p class="home-title">งานนวัตกรรม</p>
            <p class="sub-tab-title">ประเภทของนวัตกรรมจากย่านการแพทย์สวนดอก</p>
            <div v-loading="loading" class="chart-height">
              <div v-if="tabDataRespon.series.reduce((a,b)=>a+b) > 0">
                <apexchart
                  ref="chart3"
                  type="donut"
                  width="100%"
                  height="auto"
                  :options="tabDataRespon.options"
                  :series="tabDataRespon.series"
                ></apexchart>
              </div>
              <p v-else-if="loading">กำลังโหลด...</p>
              <p v-else>ไม่มีข้อมูล</p>
            </div>
            <div class="show-total">
              <p class="name-this">
                ทรัพย์สินทางปัญญา
                <span>(ย้อนหลังล่าสุด 3 ปี) </span>
              </p>
              <p class="count-this">
                <span v-if="checkData == true">{{
                  intellectualList | commaNoDot
                }}</span>
                รายการ
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="group-fotter">
          <el-col :span="24">
            <p class="text-center home-title-detail">
              ข้อมูลบุคลากร และ อุปกรณ์ทางการแพทย์
            </p>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/doctor.png" alt="SMID DOCTOR" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSpecialized | commaNoDot }}</p>
                  <p class="name-group">บุคลากรเฉพาะทาง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/nurse.png" alt="SMID NURSE" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSupoort | commaNoDot }}</p>
                  <p class="name-group">บุคลากรสนับสนุน</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/student.png" alt="SMID STUDENT" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countStudent | commaNoDot }}</p>
                  <p class="name-group">นักศึกษา</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/bed.png" alt="SMID BED" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count" v-if="checkData == true">
                    {{ countPatienBed | commaNoDot }}
                  </p>
                  <p class="name-group">เตียง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" class="see-all">
            <button
              type="button"
              @click="downLoadDocument()"
              class="btn-color-dark download"
            >
              <span>ดาวน์โหลด เอกสารสรุปนวัตกรรม</span>
            </button>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="4">
        <span slot="label">
          <p class="name-title">ภาคเอกชน อื่นๆ</p>
          <p class="count-title">
            <span>{{ countTab4 }}</span
            >หน่วย
          </p>
        </span>
        <el-row :gutter="30" class="custom-row">
          <el-col :span="24" :md="12" class="group-home-left">
            <img src="../assets/image/home-smid.png" class="w-100" alt="SMID HOME" />
          </el-col>
          <el-col :span="24" :md="12" class="group-donut">
            <p class="home-title">งานนวัตกรรม</p>
            <p class="sub-tab-title">ประเภทของนวัตกรรมจากย่านการแพทย์สวนดอก</p>
            <div v-loading="loading" class="chart-height">
              <div v-if="tabDataRespon.series.reduce((a,b)=>a+b) > 0">
                <apexchart
                  ref="chart4"
                  type="donut"
                  width="100%"
                  height="auto"
                  :options="tabDataRespon.options"
                  :series="tabDataRespon.series"
                ></apexchart>
              </div>
              <p v-else-if="loading">กำลังโหลด...</p>
              <p v-else>ไม่มีข้อมูล</p>
            </div>
            <div class="show-total">
              <p class="name-this">
                ทรัพย์สินทางปัญญา
                <span>(ย้อนหลังล่าสุด 3 ปี) </span>
              </p>
              <p class="count-this">
                <span v-if="checkData == true">{{
                  intellectualList | commaNoDot
                }}</span>
                รายการ
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="group-fotter">
          <el-col :span="24">
            <p class="text-center home-title-detail">
              ข้อมูลบุคลากร และ อุปกรณ์ทางการแพทย์
            </p>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/doctor.png" alt="SMID DOCTOR" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSpecialized | commaNoDot }}</p>
                  <p class="name-group">บุคลากรเฉพาะทาง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/nurse.png" alt="SMID NURSE" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSupoort | commaNoDot }}</p>
                  <p class="name-group">บุคลากรสนับสนุน</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/student.png" alt="SMID STUDENT" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countStudent | commaNoDot }}</p>
                  <p class="name-group">นักศึกษา</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/bed.png" alt="SMID BED" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count" v-if="checkData == true">
                    {{ countPatienBed | commaNoDot }}
                  </p>
                  <p class="name-group">เตียง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" class="see-all">
            <button
              type="button"
              @click="downLoadDocument()"
              class="btn-color-dark download"
            >
              <span>ดาวน์โหลด เอกสารสรุปนวัตกรรม</span>
            </button>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="5">
        <span slot="label">
          <p class="name-title">อุทยานวิทยาศาสตร์</p>
          <p class="count-title">
            <span>{{ countTab5 }}</span
            >หน่วย
          </p>
        </span>
        <el-row :gutter="30" class="custom-row">
          <el-col :span="24" :md="12" class="group-home-left">
            <img src="../assets/image/home-smid.png" class="w-100" alt="SMID HOME" />
          </el-col>
          <el-col :span="24" :md="12" class="group-donut">
            <p class="home-title">งานนวัตกรรม</p>
            <p class="sub-tab-title">ประเภทของนวัตกรรมจากย่านการแพทย์สวนดอก</p>
            <div v-loading="loading" class="chart-height">
              <div v-if="tabDataRespon.series.reduce((a,b)=>a+b) > 0">
                <apexchart
                  ref="chart5"
                  type="donut"
                  width="100%"
                  height="auto"
                  :options="tabDataRespon.options"
                  :series="tabDataRespon.series"
                ></apexchart>
              </div>
              <p v-else-if="loading">กำลังโหลด...</p>
              <p v-else>ไม่มีข้อมูล</p>
            </div>
            <div class="show-total">
              <p class="name-this">
                ทรัพย์สินทางปัญญา
                <span>(ย้อนหลังล่าสุด 3 ปี) </span>
              </p>
              <p class="count-this">
                <span v-if="checkData == true">{{
                  intellectualList | commaNoDot
                }}</span>
                รายการ
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="group-fotter">
          <el-col :span="24">
            <p class="text-center home-title-detail">
              ข้อมูลบุคลากร และ อุปกรณ์ทางการแพทย์
            </p>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/doctor.png" alt="SMID DOCTOR" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSpecialized | commaNoDot }}</p>
                  <p class="name-group">บุคลากรเฉพาะทาง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/nurse.png" alt="SMID NURSE" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countSupoort | commaNoDot }}</p>
                  <p class="name-group">บุคลากรสนับสนุน</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/student.png" alt="SMID STUDENT" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count">{{ countStudent | commaNoDot }}</p>
                  <p class="name-group">นักศึกษา</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-card class="box-card">
              <el-row :gutter="30" class="custom-row">
                <el-col :span="6" :md="8" class="center-img">
                  <img src="../assets/image/bed.png" alt="SMID BED" />
                </el-col>
                <el-col :span="18" :md="16">
                  <p class="count" v-if="checkData == true">
                    {{ countPatienBed | commaNoDot }}
                  </p>
                  <p class="name-group">เตียง</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24" class="see-all">
            <button
              type="button"
              @click="downLoadDocument()"
              class="btn-color-dark download"
            >
              <span>ดาวน์โหลด เอกสารสรุปนวัตกรรม</span>
            </button>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { HTTP } from "@/service/axios.js";
export default {
  mounted() {
    this.getUnder();
    this.fetchDashboard();
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    downLoadDocument() {
      HTTP.get(`export`)
        .then((res) => {
          if (res.data.success) {
            console.log();
            let routeData = this.callImage + "system/" + res.data.filename;
            window.open(routeData);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("downLoadDocument error", e);
        });
    },
    getUnder() {
      HTTP.get(`unders`)
        .then((res) => {
          if (res.data.success) {
            this.dataUnder = res.data.obj;
            console.log(this.dataUnder);
            var roundAll = { under: "all" };
            var round1 = { under: this.dataUnder[0]._id };
            var round2 = { under: this.dataUnder[1]._id };
            var round3 = { under: this.dataUnder[2]._id };
            var round4 = { under: this.dataUnder[3]._id };
            var round5 = { under: this.dataUnder[4]._id };
            HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;

            HTTP.post(`dashboard`, roundAll)
              .then((res) => {
                if (res.data.success) {
                  this.countTabAll = res.data.obj.services;
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get dashboard error", e);
              });
            HTTP.post(`dashboard`, round1)
              .then((res) => {
                if (res.data.success) {
                  this.countTab1 = res.data.obj.services;
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get dashboard error", e);
              });
            HTTP.post(`dashboard`, round2)
              .then((res) => {
                if (res.data.success) {
                  this.countTab2 = res.data.obj.services;
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get dashboard error", e);
              });
            HTTP.post(`dashboard`, round3)
              .then((res) => {
                if (res.data.success) {
                  this.countTab3 = res.data.obj.services;
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get dashboard error", e);
              });
            HTTP.post(`dashboard`, round4)
              .then((res) => {
                if (res.data.success) {
                  this.countTab4 = res.data.obj.services;
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get dashboard error", e);
              });
            HTTP.post(`dashboard`, round5)
              .then((res) => {
                if (res.data.success) {
                  this.countTab5 = res.data.obj.services;
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get dashboard error", e);
              });
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("getUnder error", e);
        });
    },
    openInNewTab(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    fetchDashboard() {
      this.loading = true;

      // this.checkData = false;
      this.tabDataRespon.series = [0,0,0,0,0,0];

      if (this.activeName == "all") {
        this.underId = this.activeName;
      }else if (this.activeName == 1) {
        this.underId = this.dataUnder[0]._id;
      }else if (this.activeName == 2) {
        this.underId = this.dataUnder[1]._id;
      }else if (this.activeName == 3) {
        this.underId = this.dataUnder[2]._id;
      }else if (this.activeName == 4) {
        this.underId = this.dataUnder[3]._id;
      }else if (this.activeName == 5) {
        this.underId = this.dataUnder[4]._id;
      }
      var under = { under: this.underId };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;

      HTTP.post(`dashboard`, under)
        .then((res) => {
          if (res.data.success) {
            // console.log(res.data);
            this.respon = res.data.obj;

            let data = [
              this.respon.innovation.diginotics,
              this.respon.innovation.medical,
              this.respon.innovation.pharmaceutical,
              this.respon.innovation.immunization,
              this.respon.innovation.eHealth,
              this.respon.innovation.other,
            ];
            console.log("data",data)

            this.intellectualList = this.respon.intellectual;
            this.countSpecialized = this.respon.specialized;
            this.countSupoort = this.respon.supoort;
            this.countStudent = this.respon.student;
            this.countPatienBed = this.respon.patienBed;
            this.checkData = true;
            setTimeout(() => {
              console.log("this.tabDataRespon.series ",this.tabDataRespon.series)
              if (typeof this.$refs[`chart${this.activeName}`] !== "undefined") {
                this.$refs[`chart${this.activeName}`].updateOptions({
                  series: data,
                });
              }
              this.tabDataRespon.series = data

              this.loading = false;
            },500)

          } else {
            this.alertFailError();
            this.loading = false;
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("getUnder error", e);
          this.loading = false;
        });
    },
  },
  data() {
    return {
      callImage: process.env.VUE_APP_BASE_URL_GET,
      checkData: false,
      loading: true,
      activeName: "all",
      underId: "all",
      dataUnder: [],
      respon: [],
      countTabAll: "",
      countTab1: "",
      countTab2: "",
      countTab3: "",
      countTab4: "",
      countTab5: "",
      countSpecialized: "",
      countSupoort: "",
      countStudent: "",
      countpatienBed: "",
      tabDataRespon: {
        intellectualList: "",
        series: [0,0,0,0,0,0],
        options: {
          responsive: [
            {
              breakpoint: 1199,
              options: {
                chart: {
                  width: "100%",
                  height: 480,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 767,
              options: {
                legend: {
                  position: "bottom",
                },
              },
              plotOptions: {
                pie: {
                  startAngle: 0,
                  expandOnClick: true,
                  offsetX: -1000,
                  offsetY: 80,
                },
              },
            },
            {
              breakpoint: 500,
              options: {
                chart: {
                  width: "100%",
                  height: 500,
                },
                legend: {
                  position: "bottom",
                },
                plotOptions: {
                  pie: {
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
              },
            },
            {
              breakpoint: 400,
              options: {
                chart: {
                  width: "100%",
                  height: 520,
                },
                legend: {
                  position: "bottom",
                },
                plotOptions: {
                  pie: {
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
              },
            },
          ],
          dataLabels: {
            style: {
              fontSize: "16px",
              fontWeight: "bold",
              colors: ["#FFFFFF"],
            },
          },
          labels: [
            "Diagnostics and Laboratory technology",
            "Medical Devices",
            "Pharmaceutical and therapeutic",
            "Immunization, vaccine and biologicals",
            "eHealth, mHealth, Telehealth, Medical software and Imaging",
            "Other",
          ],
          legend: {
            formatter: function (seriesName, opts) {
              return [
                "  ",
                opts.w.globals.series[opts.seriesIndex],
                " - ",
                seriesName,
              ];
            },
            markers: {
              radius: 0,
            },
          },
          colors: [
            "#3399E0",
            "#1EBC9B",
            "#9B59B2",
            "#465C73",
            "#E5AC27",
            "#F06363",
          ],
        },
      },
    };
  },
};
</script>
<style scope lang="scss">
.btn-color-dark.download {
  min-width: 280px !important;
}
.apexcharts-legend-text {
  font-size: 16px !important;
}
.apexcharts-legend-series {
  @media (min-width: 400px) {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-box-orient: vertical !important;
  }
  &:first-child {
    padding-top: 10px !important;
  }
}
</style>