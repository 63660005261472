<template>
  <el-card class="card-blog">
    <a class="linknews" :href="`/blog/detail/${dataBlog.id}`">
      <div class="box-zoom-img">
        <div class="overlay">
          <span class="name-in-overlay">{{ dataBlog.title }}</span>
          <img :src="getGhostImage(dataBlog.feature_image,420)" />
        </div>
      </div>
    </a>
    <div class="box-detail-blog">
      <a class="linknews" :href="`/blog/detail/${dataBlog.id}`">
      <p class="subtitle-blog">{{ dataBlog.excerpt }}</p>
      </a>
      <div class="category-blog">
        <p v-for="(category, index) in dataBlog.category" :key="index">
          <span
            class="type1"
            v-if="category.name == 'service'"
            @click="clickCategory('service')"
            >Service</span
          >
          <span
            class="type2"
            v-if="category.name == 'innovation-2'"
            @click="clickCategory('innovation-2')"
            >Innovation</span
          >
          <span
            class="type3"
            v-if="category.name == 'business'"
            @click="clickCategory('business')"
            >Business</span
          >
          <span
            class="type4"
            v-if="category.name == 'startup'"
            @click="clickCategory('startup')"
            >Startup</span
          >
          <span
            class="type5"
            v-if="category.name == 'article'"
            @click="clickCategory('article')"
            >Article</span
          >
          <span
            class="type6"
            v-if="category.name == 'event'"
            @click="clickCategory('event')"
            >Event</span
          >
          <span
            class="type7"
            v-if="category.name == 'news'"
            @click="clickCategory('news')"
            >News</span
          > <span
            class="type8"
            v-if="category.name == 'home'"
            @click="clickCategory('home')"
            >Home</span
          >
        </p>
      </div>
      <p class="time">
        SMID <span>{{ dataBlog.updated_at | timeTh }}</span>
      </p>
    </div>
  </el-card>
</template>
<script>
export default {
  props: {
    dataBlog: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    clickCategory(type) {
      this.$emit("dataCategory", type);
    },
  },
};
</script>