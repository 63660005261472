<template>
  <div id="home" class="content">
    <div class="box-inpage home-img-out">
      <div class="container">
        <el-row :gutter="30" class="center-in-monitor">
          <el-col :span="24" :md="13" class="set-center-tb">
            <p class="title-page">
              ย่านนวัตกรรม
              <br />การแพทย์สวนดอก
            </p>
            <span class="sub-name-page" v-if="getEvent == 0"
              >Suandok Medical Innovation District</span
            ><span class="sub-name-page" v-else-if="getEvent == 1"
              >The center of Excellence and interdisciplinary medical service </span
            ><span class="sub-name-page" v-else-if="getEvent == 2"
              >Driving health care through innovation </span
            ><span class="sub-name-page" v-else
              >Transfer research and innovation to market</span
            >
            <p class="small-text" v-if="getEvent == 0">
              ย่านนวัตกรรมการแพทย์สวนดอก เป็นศูนย์รวมการให้บริการทางการแพทย์
              การพัฒนาวิจัยและนวัตกรรม และส่งเสริมการลงทุน
            </p>
            <p class="small-text" v-else-if="getEvent == 1">
              แหล่งรวมของศูนย์ความเป็นเลิศด้านการแพทย์เฉพาะทางและการบูรณาการองค์ความรู้ข้ามสาขา
              (Transdisciplinary)
            </p>
            <p class="small-text" v-else-if="getEvent == 2">
              ขับเคลื่อนการให้บริการทางการแพทย์ผ่านการพัฒนานวัตกรรมและ Start Up
            </p>
            <p class="small-text" v-else>
              ส่งเสริมการลงทุนและเชื่อมโยงองค์ความรู้ทางการแพทย์สู่การใช้งาน
              <br />
              เชิงพาณิชย์
            </p>
            <div class="group-search"></div>
          </el-col>
        </el-row>
      </div>
      <div class="ele-group-home">
        <div class="box-all">
          <el-carousel
            indicator-position="none"
            arrow="always"
            @change="setStyleCarousel($event)"
            :interval="5000"
          >
            <el-carousel-item class="customs-item fade">
              <img src="../assets/image/slide1.png" alt />
            </el-carousel-item>
            <el-carousel-item class="customs-item fade">
              <img src="../assets/image/slide2.png" alt />
            </el-carousel-item>
            <el-carousel-item class="customs-item fade">
              <img src="../assets/image/slide3.png" alt />
            </el-carousel-item>
            <el-carousel-item class="customs-item fade">
              <img src="../assets/image/slide4.png" alt />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="box-group-search">
      <div class="container">
        <el-row :gutter="30">
          <el-col :span="24" :sm="12" :md="8"
            ><div class="tab-l" id="myDIV">
              <div class="box-side inside-one" @click="addActive(1)">
                <img src="../assets/image/icon/idea.svg" alt="" />
                งานนวัตกรรม
              </div>
              <div class="box-side inside-two active" @click="addActive(2)">
                <img src="../assets/image/icon/hospital1.svg" alt="" />
                งานบริการ
              </div>
            </div>
          </el-col>
          <el-col :span="24" :sm="12" :md="16" class="tab-r">
            <!-- :fetch-suggestions="querySearch" -->
            <el-input
              :placeholder="
                activeInno == 1
                  ? 'ค้นหางานนวัตกรรม'
                  : activeInno == 2
                  ? 'ค้นหาบริการ โรค หรืออาการ'
                  : ''
              "
              class="no-radius-right"
              v-model="search"
              @keyup.13.native="goLink()"
            >
              <img
                src="../assets/image/icon/search.svg"
                alt
                class="el-icon-edit el-input__icon"
                slot="prefix"
              />
              <template slot="append">
                <el-button
                  v-if="activeInno == 1"
                  @click="goLink()"
                  type=" btn-color-dark"
                  >ค้นหา</el-button
                >
                <el-button
                  v-if="activeInno == 2"
                  @click="goLink()"
                  type=" btn-color-dark"
                  >ค้นหา</el-button
                >
              </template>
            </el-input>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="container slide-contents">
      <el-row :gutter="30">
        <el-col :span="24" :sm="24" :md="14">
          <!-- <carousel
            :per-page="1"
            :mouse-drag="true"
            :navigationEnabled="true"
            :paginationEnabled="false"
            :speed="800"
            class
          >
            <slide>
              <el-row :gutter="30" class="text-center">
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/logo_cm.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/logo_med.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/logo_nstda.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/logo_step.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/mhesi.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/nia12.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
              </el-row>
            </slide>
            <slide>
              <el-row :gutter="30" class="text-center">
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/logo_cm.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/logo_med.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/logo_nstda.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/logo_step.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/mhesi.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
                <el-col :span="8" :sm="8" class>
                  <img
                    src="../assets/image/partners/nia12.png"
                    class="logo-in-slide"
                    alt
                  />
                </el-col>
              </el-row>
            </slide>
          </carousel> -->
        </el-col>
      </el-row>
    </div>
    <div class="show-dashboard">
      <div class="container detill-content home-list-card">
        <p class="title-inside">
          Suandok Medical Innovation District Dashboard
        </p>
        <p class="blog-new">ข้อมูลย่านนวัตกรรมการแพทย์สวนดอก</p>
        <div class="inside-tab">
          <p class="header-inside">ภาคส่วนการให้บริการ</p>
          <groupInsideTab />
        </div>
      </div>
    </div>

    <div class="container detill-content home-list-card">
      <p class="title-inside">ข่าวสารที่น่าสนใจ</p>
      <p class="blog-new">บทความล่าสุด</p>
      <el-row :gutter="30" class="set-count-color-card">
        <el-col
          class="set-mt-blog"
          :span="24"
          :sm="12"
          :md="8"
          v-for="(dataBlogs, index) in Blog"
          :key="index"
        >
          <div class="group-box">
            <cardBlog :dataBlog="dataBlogs" @dataCategory="selectCategory" />
          </div>
        </el-col>
        <el-col :span="24" class="see-all">
          <button
            type="button"
            @click="$router.push({ name: 'blog' })"
            class="btn-color-dark"
          >
            <span>ดูทั้งหมด</span>
          </button>
        </el-col>
      </el-row>
    </div>
    <div class="box-show-way-regis">
      <div class="container">
        <div class="bg-way">
          <ul class="tab-list">
            <li
              :class="typeRegis1 == true ? 'active' : ''"
              @click="openTypeRegis1()"
            >
              <span class="icon">
                <img
                  src="../assets/image/icon/user-white.svg"
                  class="icon-regis"
                  alt
                />
              </span>
              <span>ผู้ใช้บริการทั่วไป</span>
            </li>
            <li
              :class="typeRegis2 == true ? 'active' : ''"
              @click="openTypeRegis2()"
            >
              <span class="icon">
                <img
                  src="../assets/image/icon/microscop-white.svg"
                  class="icon-regis"
                  alt
                />
              </span>
              <span>นักวิจัยและนวัตกร</span>
            </li>
            <li
              :class="typeRegis3 == true ? 'active' : ''"
              @click="openTypeRegis3()"
            >
              <span class="icon">
                <img
                  src="../assets/image/icon/handshake-white.svg"
                  class="icon-regis"
                  alt
                />
              </span>
              <span>ภาคเอกชนและนักลงทุน</span>
            </li>
          </ul>
          <div class="detail-way-regis">
            <el-row :gutter="30">
              <el-col :span="24" :md="12">
                <div class="name-type">
                  <span v-if="typeRegis1 == true">ผู้ใช้บริการทั่วไป</span>
                  <span v-if="typeRegis2 == true">นักวิจัยและนวัตกร</span>
                  <span v-if="typeRegis3 == true">ภาคเอกชนและนักลงทุน</span>
                </div>
                <p v-if="typeRegis1 == true" class="set-text">
                  ย่านนวัตกรรมการแพทย์สวนดอก
                  เป็นย่านที่รวมศูนย์บริการทางการแพทย์
                  ที่ทันสมัยและมีทีมผู้เชี่ยวชาญที่ทำงานร่วมกัน
                  พร้อมด้วยนวัตกรรมทางการแพทย์ เพื่อให้บริการตรวจวินิจฉัย
                  และรักษาท่านอย่างดีที่สุด
                  โดยท่านสามารถค้นหาบริการทางการแพทย์ในย่านนี้ได้ที่นี่
                  <!-- เป็นพื้นที่ที่เป็นศูนย์กลางการให้บริการทางการแพทย์ที่ทันสมัย -->
                </p>
                <p v-if="typeRegis2 == true" class="set-text">
                  เมื่อโลกหมุนเร็วขึ้นจากความเปลี่ยนแปลงอย่างรวดเร็วของเทคโนโลยีและนวัตกรรมใหม่ๆ
                  การสร้างงานวิจัยและนวัตกรรมที่มีประสิทธิภาพจึงเป็นหัวใจในการขับเคลื่อนการพัฒนาการให้บริการทางสุขภาพย่านนวัตกรรมการแพทย์สวนดอก
                  เรามีโครงการในการสนับสนุนวิจัยและนวัตกรรมต่างๆ
                  รวมถึงความร่วมมือกันของหลายหน่วยงานในการบ่มเพาะและสร้างนวัตกรรม
                  รวมถึงการมีแหล่งวิจัยสำหรับการทดสอบผลิตภัณฑ์ (Prototype)
                  สำหรับการใช้งานจริง
                </p>
                <p v-if="typeRegis3 == true" class="set-text">
                  ย่านนวัตกรรมการแพทย์สวนดอกส่งเสริม
                  ให้มีการนำนวัตกรรมไปใข้งานในวงกว้างและสนับสนุนการทำงานร่วมกันของนักวิจัย
                  นวัตกรรมและนักลงทุน
                  เพื่อให้วิจัยและนวัตกรรมเกิดการใช้งานในเชิงพาณิชย์
                  และเกิดประโยชน์แก่สังคม ผู้สนใจสามารถติดต่อร่วมงานกับเราได้
                </p>
                <ul class="sub-detail">
                  <li v-if="user == null">
                    <span>
                      <img
                        src="../assets/image/icon/regis_news.svg"
                        class="icon-regis"
                        alt
                      />
                    </span>
                    <p @click="openModalSignUp()" style="cursor: pointer">
                      สมัครเพื่อรับข้อมูลข่าวสาร และโปรโมชั่นที่ท่านสนใจ
                    </p>
                  </li>
                  <li></li>
                </ul>
                <button
                  type="button"
                  class="el-button el-button-- btn-color-dark first"
                >
                  <div v-if="typeRegis1 == true">
                    <span class="long-text" @click="clickReadMore(1)">
                      อ่านเพิ่มเติม
                    </span>
                  </div>
                  <div v-if="typeRegis2 == true">
                    <span class="long-text" @click="clickReadMore(2)"
                      >อ่านเพิ่มเติม
                    </span>
                  </div>
                  <div v-if="typeRegis3 == true">
                    <span class="long-text" @click="clickReadMore(3)">
                      อ่านเพิ่มเติม
                    </span>
                  </div>
                </button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="img-with-out">
        <span v-if="typeRegis1 == true">
          <img src="../assets/image/showregis11.png" alt />
        </span>
        <span v-if="typeRegis2 == true">
          <img src="../assets/image/showregis22.png" alt />
        </span>
        <span v-if="typeRegis3 == true">
          <img src="../assets/image/showregis33.png" alt />
        </span>
      </div>
    </div>
    <!-- <div class="box-inpage out-work-home">
      <div class="container">
        <el-row :gutter="30">
          <el-col :span="24" :md="10">
            <div class="logo-in-card">
              <img src="../assets/image/icon/network.svg" />
            </div>
            <p class="head-name-page">Our Network</p>
            <span class="sub-name-page">Medical Service Center</span>
            <p class="small-text">
              บริการค้นหาโรงพยาบาลและคลินิก ในเครือข่าย
              พร้อมสมัครเพื่อรับข้อมูลข่าวสารและโปรโมชั่น ที่ท่านสนใจ
            </p>
          </el-col>
        </el-row>
      </div>
      <groupSuandok />
    </div> -->
    <div class="container">
      <div class="box-partner">
        <p class="title-inside">Partners we work with</p>
        <span class="img-box" v-for="(partner, index) in partners" :key="index">
          <img
            :src="require(`../assets/image/partners/${partner.name}`)"
            :title="partner.title"
            class="grayscale"
            alt
          />
        </span>
      </div>
      <!-- <div class="regis-now">
        <el-row :gutter="30">
          <el-col :span="24" :sm="14" class>
            <h1>ลงทะเบียนกับเราวันนี้</h1>
            <p>
              Build consumers trust using words from your happy customers.
              Increase social proof, excitement and showcase.
            </p>
          </el-col>
          <el-col :span="24" :sm="10" class="regis-tab-r">
            <el-button
              class="btn btn-color-dark link-regis"
              @click="openModalLogin()"
            >
              สมัครสมาชิก
              <img
                src="/img/right_arrow.6908b015.svg"
                alt
                class="right_arrow"
              />
            </el-button>
          </el-col>
        </el-row>
      </div> -->
    </div>
    <modalShow id="modalAll" />
    <el-dialog
      :fullscreen="true"
      :visible.sync="readmore"
      append-to-body
      custom-class="readmore"
    >
      <div v-if="datareadmore == 1" class="container">
        <h1>ผู้ใช้บริการทั่วไป</h1>
        <el-card class="box-card" shadow="always">
          <div class="text-center">
            <img src="../assets/image/showregis11.png" alt />
          </div>
          <p>
            ย่านนวัตกรรมการแพทย์สวนดอก
            เป็นพื้นที่ที่เป็นศูนย์กลางการให้บริการทางการแพทย์ที่ทันสมัย ได้แก่
            โรงพยาบาลมหาราชนครเชียงใหม่ ศูนย์ความเป็นเลิศทางการแพทย์
            ศูนย์ทันตกรรม คณะทันตแพทย์ ร้านขายยาโดยคณะเภสัชศาสตร์
            มหาวิทยาลัยเชียงใหม่ ศูนย์ให้บริการกายภาพบำบัด คณะเทคนิคการแพทย์
            และโรงพยาบาลและคลินิกเอกชน มากมาย
            โดยมีการให้บริการทั้งการตรวจสุขภาพโดยทั่วไปและการรักษาทางการแพทย์เฉพาะทางด้วยเทคโนโลยีขั้นสูง
            ศูนย์บริการต่างๆ
          </p>
        </el-card>
      </div>
      <div v-else-if="datareadmore == 2" class="container research-innovation">
        <h1>นักวิจัยและนวัตกร</h1>
        <el-card class="box-card" shadow="always">
          <div class="text-center">
            <img src="../assets/image/showregis22.png" alt />
          </div>
          <div class="detail-blog">
            <p>
              เมื่อโลกหมุนเร็วขึ้นจากความเปลี่ยนแปลงอย่างรวดเร็วของเทคโนโลยีและนวัตกรรมใหม่ๆ
              การสร้างงานวิจัยและนวัตกรรมที่มีประสิทธิภาพจึงเป็นหัวใจในการขับเคลื่อนการพัฒนาการให้บริการทางสุขภาพ
              ย่านนวัตกรรมการแพทย์สวนดอก มีระบบสนับสนุนนักวิจัยและนวัตกรรม
              ผ่านช่องทางต่างๆ ได้แก่
            </p>
            <ul>
              <li>การเข้าถึงแหล่งทุน (research & innovation funding)</li>
              <li>โปรแกรมส่งเสริมนวัตกรรม (innovation program)</li>
              <li>การสนับสนุนการจับคู่ธุรกิจ (business matching)</li>
              <li>งานสัมนาวิชาการ (medical conference)</li>
              <li>
                การให้คำปรึกษาการจดทรัพย์สินทางปัญญา (intellectual property)
              </li>
              <li>การถ่ายทอดเทคโนโลยีสู่ภาคเอกชน (technology transfer)</li>
            </ul>
            <p>
              Funding แหล่งทุน ย่านนวัตกรรมการแพทย์สวนดอก
              เป็นช่องทางที่เข้าถึงแหล่งทุนภายในจากมหาวิทยาลัยเชียงใหม่
              และแหล่งทุนภายนอก เพื่อช่วยในการขับเคลื่อนงานวิจัยและนวัตกรรม
              ให้เกิดขึ้นจริง
            </p>
            <p>
              Innovation program โปรแกรมส่งเสริมนวัตกรรม
              โปรแกรมที่จะช่วยให้งานนวัตกรรมเกิดขึ้นและเติบโตอย่างมีประสิทธิภาพ
              ทั้งจากหน่วยงานส่วนกลาง เช่น สำนักงานนวัตกรรมแห่งชาติ(National
              Innovation Agency) หรือ อุทยานวิทยาศาสตร์ภาคเหนือ (The Northern
              Science and Technology Park) ผ่านโปรแกรม เช่น Inbubation program ,
              accelerator program , Health Hackathron เป็นต้น
            </p>
            <p>
              Business matching การจับคู่ธุรกิจ
              เราเป็นสื่อกลางในการเชื่อมโยงนักวิจัยและนวัตกรรมกับภาคเอกชน
              ในการร่วมส่งเสริมการนำงานวิจัยและนวัตกรรมออกไปใช้ในเชิงพาณิชย์ได้จริง
              โดยอาจเป็นการรับโจทย์จากภาคเอกชนเพื่อมาทำงานวิจัย หรือ การ
              licensing
              งานวิจัยและนวัตกรรมที่สำเร็จแล้วออกสู่การใช้งานเชิงพาณิชย์
              โดยมีฐานข้อมูลของนวัตกรรมชนิด และ ระยะต่างๆ
              รวมถึงข้อมูลของภาคเอกชนและนักลงทุนที่สนใจ
            </p>
            <p>
              Intellectual property การจดทรัพย์สินทางปัญญา
              ทรัพย์สินทางปัญญาเป็นสิ่งที่สำคัญ
              เราให้บริการให้คำแนะนำการจดทรัพย์สินทางปัญญานวัตกรรมทางการแพทย์และส่งต่อยังหน่วยงานที่ดำเนินงานทั้งภายในแบะภายนอกมหาวิทยาลัยเชียงใหม่
              Startup สตาร์ทอัพ ในย่านนวัตกรรมการแพทย์สวนดอก ได้มีการรวมกลุ่ม
              CNX Health Tech Startup
              ขึ้นเพื่อเชื่อมโยงและแลกเปลี่ยนข้อมูลในการพัฒนาผลิตภัณฑ์และนวัตกรรมและช่วยส่งเสริมระบบนิเวศของ
              Startup (Startup Ecosystem) และการเข้าถึงแหล่งทุน
            </p>
            <p>
              Regulatory sandbox พื้นที่ทดลองนวัตกรรมทางการแพทย์
              สนับสนุนการนำผลงานวิจัยและนวัตกรรม
              มาทดลองหรือทดสอบโดยการสร้างกลไกที่เรียกว่า “Regulatory Sandbox”
              ที่เป็นสภาพแวดล้อมที่เหมือนตลาดจริง
              เพื่อส่งเสริมสนับสนุนให้เกิดการสร้างนวัตกรรมหรือโมเดลทางธุรกิจใหม่ๆ
            </p>
          </div>
        </el-card>
      </div>
      <div v-else class="container">
        <h1>ภาคเอกชนและนักลงทุน</h1>
        <el-card class="box-card" shadow="always">
          <div class="text-center">
            <img src="../assets/image/showregis33.png" alt />
          </div>

          <el-row :gutter="30" class="innovation-business">
            <el-col :span="24" class="detail-blog">
              <p class="set-text">
                ในปัจจุบัน
                หนึ่งในกุญแจของความสำเร็จคือความสามารถในการนำผลผลิตของงานวิจัยและนวัตกรรมไปใช้ในเชิงพาณิชย์และเป็นประโยชน์แก่สังคมในวงกว้าง
                SMID
                มีนโยบายส่งเสริมการดำเนินงานดังกล่าวโดยเป็นแหล่งรวมงานวิจัยและนวัตกรรม
                และ นวัตกร ที่พร้อมสร้างคุณค่าร่วมกับภาคเอกชนและนักลงทุนที่สนใจ
              </p>
            </el-col>
            <el-col :span="24" :md="8" class="detail-blog">
              <p class="sub-detail-blog">From research to Market</p>
              <img src="../assets/image/invester11.png" alt />
              <p class="set-text">
                จุดเด่นของการนำงานวิจัยและนวัตกรรมจากย่านนวัตกรรมทางการแพทย์สวนดอกคือ
                การกลั่นกรองถึงประสิทธิภาพและความปลอดภัยภายใต้กระบวนการทางการแพทย์ที่ได้มาตรฐานในระดับสากล
                และสามารถนำไปเป็นจุดเด่นในเชิงพาณิชย์ได้ นอกจากนี้
                เรายังเป็นสถานที่ที่นำนวัตกรรมมาให้บริการแก่ประชาชนในพื้นที่
                เพื่อได้ใช้งานจริง
              </p>
            </el-col>
            <el-col :span="24" :md="8" class="detail-blog">
              <p class="sub-detail-blog">Business matching</p>
              <img src="../assets/image/invester2.png" alt />
              <p class="set-text">
                เราให้บริการในการสืบค้นงานวิจัยในแขนงต่างๆ
                เพื่อให้ตรงกับความต้องการของตลาดและนักลงทุน
                และยังมีรูปแบบในการจับคู่ธุรกิจที่หลากหลาย
                เพื่อให้เหมาะสมในการพัฒนาผลิตภัณฑ์จากงานวิจัย
              </p>
            </el-col>
            <el-col :span="24" :md="8" class="detail-blog">
              <p class="sub-detail-blog">Licensing</p>
              <img src="../assets/image/invester3.png" alt />
              <p class="set-text">
                ย่านนวัตกรรมการแพทย์สวนดอกมีกระบวนการจัดการทรัพย์สินทางปัญญา
                (Intellectual property management) และช่วยในการถ่ายทอดเทคโนโลยี
                (Technology transfer)
                เพื่ออำนวยความสะดวกแก่ภาคเอกชนในการนำงานวิจัยและนวัตกรรมไปใช้งานเชิงพาณิชย์
              </p>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss"  scope="scoped">
.sub-detail li:last-child {
  height: 35px;
  border-bottom: none !important;
  @media (max-width: 1199.98px) {
    display: none !important;
  }
}
.el-input-group__append {
  background-color: #9e76b4;
  border: 0 solid #9e76b4;
}
.center-in-monitor {
  @media (min-width: 991.98px) {
    padding-top: 10vh;
  }
}
.research-innovation {
li {
  font-size: 22px;
}
}

.innovation-business {
  img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
  }
  .detail-blog {
    .sub-detail-blog {
      text-align: center;
      font-size: 26px;
      color: #1c3f5b;
      margin: 15px 0;
      font-weight: 500;
    }
    img {
      max-height: 310px;
      object-fit: contain;
    }
  }
  p {
    // text-indent: 50px;
    text-align: justify;
    &.title-detailblog {
      text-indent: 0;
      font-size: 30px;
      color: #1c3f5b !important;
      margin: 15px 0;
      font-weight: 500;
    }
  }
}
</style>

<script>
import cardBlog from "@/components/cardBlog";
import modalShow from "@/components/modalShow";
import groupInsideTab from "@/components/GroupInsideTab";
import { BLOG } from "@/service/axios.js";
export default {
  components: {
    cardBlog,
    modalShow,
    groupInsideTab,
  },
  computed: {
    symptomList() {
      return this.$store.state.symptomList;
    },
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      datareadmore: null,
      readmore: false,
      getEvent: 0,
      scrollPosition: null,
      typeRegis1: true,
      typeRegis2: false,
      typeRegis3: false,
      search: "",
      activeInno: 2,
      partners: [
        { name: "mhesi.png", title: "mhesi" },
        { name: "nia12.png", title: "" },
        { name: "logo_med.png", title: "Medical" },
        { name: "logo_pharmacy.png", title: "Pharmacy" },
        { name: "logo_dent.png", title: "Dental" },
        { name: "logo_medtech.png", title: "Med-Tech" },
        { name: "logo_nursing.png", title: "Nursing" },
        { name: "logo_arch.png", title: "" },
        { name: "logo_entaneer.png", title: "Entaneer" },
        { name: "logo_step.png", title: "" },
        { name: "logo_chiangmairam.png", title: "Chiang Mai Ram" },
        { name: "logo_cmneuro.png", title: "" },
        { name: "logo_publichealth.png", title: "" },
        { name: "logo_nakorncm.png", title: "" },
        { name: "logo_sapar.png", title: "" },
        { name: "logo_hokankr.png", title: "" },
        { name: "logo_boi.png", title: "" },
        { name: "logo_nstda.png", title: "" },
        { name: "logo_tcels.png", title: "" },
        { name: "logo_chivacare.png", title: "" },
      ],
      Blog: [],
    };
  },
  mounted() {
    this.getBlog();
    this.checkRoleAdmin();
  },
  methods: {
    selectCategory(val) {
      this.$router.push(`/blog?type=${val}`);
    },
    goLink() {
      if (this.activeInno == 1) {
        var dataSend = "";
        if (this.search == "") {
          dataSend = "all";
        } else {
          dataSend = this.search;
        }
        this.$router.push(`/service/innovation?search=${dataSend}`);
      } else if (this.activeInno == 2) {
        this.$router.push(
          `/service/?search=${this.search}&state=${this.activeInno}`
        );
      }
    },
    getBlog() {
      BLOG.get(
        `posts?key=78503f5d740a46935840ac886a&page=1&limit=6&filter=featured:true`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.thisData = res.data.posts;
            for (var a = 0; a < this.thisData.length; a++) {
              this.thisData[a].category = [];
            }
            console.log("asdasdasdad", this.thisData);
            await BLOG.get(`tags?key=78503f5d740a46935840ac886a`)
              .then(async (res) => {
                if (res.status == 200) {
                  var tags = res.data.tags;
                  for (var i = 0; i < tags.length; i++) {
                    var changeString = tags[i].slug;
                    await BLOG.get(
                      `posts/?key=78503f5d740a46935840ac886a&filter=tag:${changeString}`
                    )
                      .then((res) => {
                        if (res.status == 200) {
                          for (var y = 0; y < this.thisData.length; y++) {
                            var checkObj = res.data.posts.find(
                              (e) => e.id === this.thisData[y].id
                            );
                            if (checkObj != undefined) {
                              var getCategory = { name: changeString };
                              this.thisData[y].category.push(getCategory);
                            }
                          }
                        } else {
                          this.alertFailError();
                        }
                      })
                      .catch((e) => {
                        this.alertCatchError(e);
                        console.log("get blog error");
                      });
                  }
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get blog error");
              });

            // var arrayFirst = this.thisData.slice(0, 1);
            // var arrayAll = this.thisData.slice(1, res.data.posts.length);
            // this.all = this.thisData;
            // this.BlogFirst = arrayFirst;
            this.Blog = this.thisData;
            // this.total = res.data.meta.pagination.total;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    clickReadMore(val) {
      this.readmore = true;
      if (val == 1) {
        this.datareadmore = 1;
      } else if (val == 2) {
        this.datareadmore = 2;
      } else {
        this.datareadmore = 3;
      }
    },
    openModalSignUp() {
      this.$store.commit("SET_MODAL", true);
      this.$store.commit("TAB_REGIS", true);
    },
    addActive(val) {
      var header = document.getElementById("myDIV");
      var btns = header.getElementsByClassName("box-side");
      if (val == 1) {
        btns[1].classList.remove("active");
        btns[0].classList.add("active");
        this.activeInno = 1;
      }
      if (val == 2) {
        btns[0].classList.remove("active");
        btns[1].classList.add("active");
        this.activeInno = 2;
      }
    },
    setStyleCarousel(event) {
      this.getEvent = event;
    },
    querySearch(queryString, cb) {
      var results = queryString
        ? this.symptomList.filter(
            (filter) =>
              filter.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1
          )
        : this.symptomList;

      cb(results);
    },
    checkRoleAdmin() {
      if (this.user == null) {
        this.$router.push(`/home`).catch(() => {});
      } else if (this.user.role == 50) {
        this.$router.push(`/management`).catch(() => {});
      }
    },
    openModalLogin() {
      this.$store.commit("SET_MODAL", true);
      this.$store.commit("TAB_LOGIN", true);
    },
    openTypeRegis1() {
      this.typeRegis1 = true;
      this.typeRegis2 = false;
      this.typeRegis3 = false;
    },
    openTypeRegis2() {
      this.typeRegis1 = false;
      this.typeRegis2 = true;
      this.typeRegis3 = false;
    },
    openTypeRegis3() {
      this.typeRegis1 = false;
      this.typeRegis2 = false;
      this.typeRegis3 = true;
    },
  },
};
</script>